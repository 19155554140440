<template>
  <main class="container backprint" >
    <div class="header h3 mt-2">
          <span class="header" v-if="row.responsabilityId"> Detalles Tarjeta de Responsabilidad / {{ padDigits(row.responsabilityId, 5)}}</span>
        <!-- <button class="btn-2 float-end" @click="showNewModal=true"><i class="fas fa-plus me-2"/> Crear Nuevo</button> -->
    </div>

    <!-- DETAILS -->
    <!-- <div class="row mt-2 mx-1" style="min-height:75vh; background:rgba(0,0,0,0.5); padding:20px"> -->
    <div ref="document" class="row mt-2 " style="min-height:75vh; padding:20px">
        <div class="row">
            <!-- <div class="col-md-6">
                <label class="text-green-2 mt-2">Almacén de salida:</label>
                <select class="form-select border-secondary" required v-model="newItem.warehouseId" @change="selectWarehouse()">
                    <option :value="undefined" disabled>* seleccione</option>
                    <option :value="warehouse.id" v-for="warehouse in warehouses" :key="warehouse.id">{{warehouse.name}}</option>
                </select>            
            </div> -->
            <div class="col-md-6">
                <label class="text-black mt-2">Id Transferencia: {{row.transferId}}</label><br>
                <label class="text-black mt-2">Emisor: {{row.username}}</label><br>
                <label class="text-black mt-2">Sub-Estación: {{row.receiverStationName}}</label><br>
                <!-- <label class="text-black mt-2">Puesto que desempeña: {{row.employeeRole}}</label><br> -->
            </div>
            <div class="col-md-6">
                <label class="text-black mt-2">Fecha: {{$moment(row.creationDate).format('DD-MM-YYYY')}}</label><br>
                <label class="text-black mt-2">Responsable: {{ row.employeeMame}}</label><br>
                <label class="text-black mt-2">Número de identificación Tributaria: {{row.nit}}</label><br>
                <!-- <label class="text-black mt-2">Fecha de entrada: {{row.senderUserName}}</label> -->
            </div>
        </div>
        <div class="row">
            <table class="table">
            <thead>
                <tr>
                <th class="black" scope="col">Fecha de cargo</th>
                <th class="black" scope="col">Código</th>
                <th class="black" scope="col">Descripción</th>
                <th class="black" scope="col">Observaciones</th>
                <th class="black" scope="col">Valor del bien</th>
                </tr>
            </thead>
            <tbody v-for="item of items" :key="item.id">
                <tr>
                <td class="black" >{{$moment(row.creationDate).format('DD-MM-YYYY')}}</td>
                <td class="black" >{{item.code}}</td>
                <td class="black" >{{item.name}}</td>
                <td class="black" >{{item.observations}}</td>
                <td class="black" >{{'Q' + item.price}}</td>
                </tr>
            </tbody>
            </table>

        </div>
        <!-- <div class="row black" style="top:10px">
            <b-table  table-white
            id="my-table"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields"
            selected-variant="dark"
            >
            
                 <template #cell(price)="data">
                    <div>                        
                        <span>{{'Q ' + data.item.price.toFixed(2)}}</span>
                    </div>
                </template>
                 <template #cell(fecha)="data">
                    <div>                        
                        <span>{{$moment(row.creationDate).format('DD-MM-YYYY')}}</span>
                    </div>
                </template>
            </b-table> 

            <b-pagination class="float-end" v-if="items.length > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            ></b-pagination>
        </div> -->
    </div>
        <div class="row">
            <div class="col-md-12">
                <button id="btnCrearPdf" ref="btnCrearPdf" name="btnCrearPdf" class="btn-2 float-end mt-3" @click="print()">Imprimir &nbsp;&nbsp;<i class="fas fa-print me-2"/></button>
            </div>
        </div>
  </main>
</template>

<script>
	import html2pdf from 'html2pdf.js'

export default {
props:["id"],
computed: {
    rows() {
    return this.items.length
    }
},
data(){
    return {
        items:[],
        row:{},
        perPage: 10,
        currentPage: 1,

        showEditModal:false,
        table: 'transfers',
        transferId: 0,
        responsabilityDocs:[]
    }
},
async mounted(){
    this.transferId = this.$route.params.id;
    await this.getData()
},
methods:{
    print(){
        html2pdf(this.$refs.document, {
            margin: 0.5,
            filename: 'document.pdf',
            image: { type: 'jpeg', quality: 0.98 },

            html2canvas: { dpi: 192, letterRendering: true },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
            previewModal: true
        })

    },
    padDigits(number, digits) {
        return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number;
    },    
    async getData(){
        try {
            let res = await this.$store.dispatch('get', {path: this.table + '/getTransferContractDetails/'+ this.transferId});
            if (res){
                this.row = res.data[0]
                this.items = res.items
                console.log(this.row)
                this.responsabilityDocs = res.responsabilityDocs[0]

            }
        } catch (error) {
            this.row = {}
            console.error('error', error)
        }
    }
     

}
}
</script>

<style scoped>

.backprint{
    background-color: white !important;
}
.black {
    color:black !important
}



</style>